.partners {
    width: 100%;
    background: #415CED;
}

.partners .info {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    row-gap: 30px;

}

.partners .info .block {
    min-height: 90px;
   padding: 10px;
    border-radius: 20px;
    background-color: white;
    width: 10%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: auto;
}
.partners .info .block a {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.partners .info .block img {
    width: auto;
    max-height: 90px;
    max-width: 100%;
}

@media screen and (max-width: 1200px) {
    .partners .info .block {
        width: 21%;
    }
}
@media screen and (max-width: 768px) {
    .partners .info {
        padding-top: 40px;
        padding-bottom: 40px;

    }
    .partners .info .block {
        width: 43%;
    }
    .partners .info .block img {
        width: auto;
        max-width: 100%;
    }

}
@media screen and (max-width: 500px) {
    .partners .info {
        padding-top: 40px;
        padding-bottom: 40px;

    }
    .partners .info .block {
        width: 100%;
    }

}