.footer {
    border-top: 1px solid black;
    padding-top: 55px;
    padding-bottom: 55px;
    font-weight: 400;

}
.footer .info {
    width: calc(90%);
    max-width: 1640px;
    margin: 0 auto;
}
.footer .info .upper {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 68px
}

.footer .info .upper .logo a {
    height: 68px !important;

}

.footer .info .upper .logo a img {
    height: 68px !important;
}

.footer .info .upper .nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 40px;
}
.footer .info .upper .nav a {
    color: #4D4D4D;
    font-size: 20px;
    text-decoration-line: none;
}
.footer .info .upper .nav a:hover{
    text-decoration-line: underline;
}
.footer .info .upper .social {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;
}

.footer .info .bottom {
    padding-top: 40px;
    display: flex;
    column-gap: 60px;
    font-size: 16px;
    justify-content: space-between;
    color: #4D4D4D;
    width: 95%;
    margin: 0 auto;
}
.footer .info .bottom a {
    color: #4D4D4D;
    text-decoration-line: none;
}
.footer .info .bottom a:hover {
    text-decoration-line: underline;
}

.footer .info .bottom .infoLeft {
    display: flex;
    gap: 24px;
}

.infoBlock {
    font-size: 16px;
    max-width: 390px;
}
.infoBlock h4 {
    font-weight: 500;
    margin-top: 20px;
}
.infoBlock a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    column-gap: 9px;
    width: max-content;
}

.yt {
    display: flex;
    padding: 12px;
    gap: 24px;
    align-items: center;
    border: 1px solid #4D4D4D;
    border-radius: 4px;
    height: 70px;
}

.yt img {
   width: 71px;
}
.yt .ytText {
   width: 173px;
}

.sideTitle {
    padding-top: 40px;
    color: #4D4D4D;
    text-align: center;
}
.sideTitle a {
    color: #4D4D4D;
    text-decoration: none;
}

.ask {
    margin-top: 10px;
    font-family: Jost, sans-serif;
}

.ask:hover span {
    text-decoration: underline;
}

.ytIcon {
    width: 61px;
    height: 42px;
    background-image: url('../../assets/Footer/YT.svg'); /* Стандартная иконка */
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-image 0.1s ease;
}

.yt:hover .ytIcon {
    background-image: url('../../assets/Footer/RYT.svg'); /* Иконка при наведении */
}

@media screen and (max-width: 1200px) {

    .footer .info .upper {
        flex-direction: column;
        align-items: center;
    }

    .footer .info .bottom {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: 40px;
    }

    .footer .info .upper .nav {
        display: none;
    }
    .footer .info .upper .social {

        padding-top: 40px;
        justify-content: center;
        width: 100%;
    }
    .footer .info .bottom .infoLeft {
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }
    .infoBlock {
        font-size: 14px;
        max-width: 300px;
    }
}
@media screen and (max-width: 768px) {
    .yt {
        font-size: 14px;
        margin-bottom: 30px;
        margin-top: 40px;
    }

    .yt img {
        width: 42px;
    }
    .yt .ytText {
        width: auto;
    }
    .yt .ytText a {
        text-decoration-line: none;
    }
    .yt:hover .ytText a {
        text-decoration-line: underline;
    }

    .sideTitle {
        text-align: left;
    }
    .info a {
        text-decoration-line: none;
        color: #4D4D4D;
    }
    .info a:hover {
        text-decoration-line: underline;
    }
}