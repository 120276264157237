.main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    padding-top: 50px;
    width: calc(90% * 0.95);
    margin: 0 auto;
    color: #4D4D4D;
    max-width: 1640px;
}

.main .info h1{
    margin-top: 0;
    font-size: 64px;
    margin-bottom: 40px;
}

.main .info p{
    font-size: 24px;
    margin-bottom: 45px;
    width: 100%;
}

.main .data p{
    font-size: 24px;
    margin-bottom: 45px;
    width: 100%;
    display: flex;
}
.main .data h2{
    margin-top: 0;
    font-size: 64px;
    margin-bottom: 40px;
}

.main img{
    width: 375px;
    height: 375px;
    border: 1px solid black;
    border-radius: 100%;
}

.bottom {
    width: calc(90% * 0.95);
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    padding-top: 40px;
    margin: 0 auto;
    column-gap: 50px;
    padding-bottom: 40px;
}

@media screen and (max-width: 1200px) {

    .main{
        padding-top: 50px;
        gap: 25px
    }


    .main img{
        border-radius: 100%;
        width: 300px;
        height: 300px;
    }
    .main .info h1{
        margin-top: 0;
        font-size: 52px;
        margin-bottom: 40px;
    }

    .main .data h2{
        margin-top: 0;
        font-size: 56px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 992px) {

    .main{
        padding-top: 50px;
    }

    .main img{
        border-radius: 100%;
        width: 250px;
        height: 250px;
    }

    .main .info h1{
        font-size: 34px;
    }

    .main .data h2 {
        font-size: 34px;
    }

    .main .info p{
        font-size: 15px;
    }

    .main .data p {
        font-size: 15px;

    }
}

@media screen and (max-width: 768px) {

    .main{
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;
        height: auto;
    }

    .main .info h1{
        font-size: 40px;
        margin-bottom: 16px;
    }
    .main .data h2{
        font-size: 30px;
        margin-bottom: 16px;
    }

    .main .info p{
        margin-bottom: 16px;
    }
    .main .data p{
        margin-bottom: 16px;
    }

    .main .info button{
        padding: 6px 20px;
        font-size: 13px;
    }

    .main img{
        order: 1;
        width: 500px;
        height: 500px;
        border-radius: 100%;
        margin-bottom: 20px;
    }

}
@media screen and (max-width: 568px) {
    .main img{
        order: 1;
        width: 300px;
        height: 300px;
        border-radius: 100%;
        margin-bottom: 20px;
    }

}
@media screen and (max-width: 368px) {
    .main img{
        order: 1;
        width: 200px;
        height: 200px;
        border-radius: 100%;
        margin-bottom: 20px;
    }

}