.gallery {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.gallery h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}

.folder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    justify-content: center;
    color: rgba(56, 95, 235, 1);
}
.folder .date {
    color: #4D4D4D;
}
.image {
    height: 100%;
}
.image .upper {
    max-height: 260px;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    overflow: hidden;
}

.image .upper img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;

}

.title {
    font-size: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .image .upper {
        max-height: 310px;
    }

}
@media screen and (max-width: 768px) {
    .image .upper {
        max-height: max-content;
        flex: none;
    }

}