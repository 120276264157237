.news {
    width: 100%;
    padding-bottom: 75px;
    border-bottom: 1px solid rgba(186, 143, 150, 1);

}
.news .info {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;

}

.text {
    font-family: 'Jost', sans-serif;

}

.text h3 {
    font-weight: 500;
    font-size: 22px;
    margin: 0;
    padding: 0;
    height: calc(1.3em * 3);
    color: #4D4D4D;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.text {
    color: #4D4D4D;
}
.text p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
}
.text div {
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
}

.card {
    background-color: white;
    height: 100%;
    border: 1px solid black;
}

.event {
    padding-top: 10px;
    font-family: 'Jost', sans-serif;
    display: flex;
    justify-content: space-between;
}



@media screen and (max-width: 768px){
    .card {
        height: auto;
    }
}

.dateAnonce{
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    background-color: rgba(65, 92, 237, 1);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top:-20px;
    z-index: 2;
    width: 70%;
    border-radius: 5px;
}
.textNews{
    overflow: hidden;            /* Обрезать содержимое, выходящее за пределы блока */
    display: -webkit-box;        /* Использовать Flexbox для обрезки по количеству строк */
    -webkit-box-orient: vertical; /* Установить вертикальную ориентацию */
    -webkit-line-clamp: 4;       /* Количество видимых строк */
    text-overflow: ellipsis;     /* Добавить троеточие в конце обрезанного текста */
    line-height: 1.3;            /* Межстрочный интервал */
    padding: 10px;               /* Внутренний отступ */
    box-sizing: border-box; 
}