.three {
    width: 100%;
    display: flex;
    column-gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.three .left {
    display: flex;
    width: 60%;
    flex-direction: column;
    row-gap: 10px;
}



.block {

    font-size: 16px;
    padding: 50px 70px;
    padding-bottom: 30px;
    border-radius: 20px;
}

.block h2 {
    font-size: 32px;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.black {
    background: #4D4D4D;
    color: white;
}
.black a {
    color: white;
}

.white {
    background: white;
    color: #4D4D4D;
}

.write {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: auto;
    border: 1px solid;
    width: calc(40% - 20px);
    border-radius: 20px;
}

.write img{
    position: absolute;
    max-width: 100%;
    top:0
}

.touch {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .three {
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        row-gap: 15px;
    }
    .block {
        border: 1px solid black;
        padding: 25px;
        text-align: justify;
    }
}
@media screen and (max-width: 330px) {
    .block h2 {
        font-size: 24px;
    }
}