.form {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;

}
.form h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}