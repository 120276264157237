.header {
  width: 100%;
  background-color: white;
  font-family: "Jost", sans-serif;
  font-weight: 400;
}

.header a {
  text-decoration-line: none;
  font-family: "Jost", sans-serif;
}
.header button {
  font-family: "Jost", sans-serif;
  transition: color 0.3s;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  width: calc(90% * 0.95);
  max-width: 1640px;
  margin: 0 auto;
  position: relative;
}
.bottom {
  margin: 0 auto;
}

.left {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.left a {
  width: 211px;
}
.left a img {
  width: 100%;
  height: 100%;
}
.left article {
  max-width: 335px;
  font-size: 20px;
  line-height: 1.2;
  color: #2c3e50;
}

.right {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.languages {
  font-size: 20px;
  display: flex;
  column-gap: 5px;
}
.languages button {
  color: black;
  padding: 0;
}

.languages button:disabled {
  color: rgba(0, 0, 0, 0.5);
  cursor: unset;
}

.nav {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 250, 250, 1) 100%
  );
}

.nav a {
  color: #4d4d4d;
  font-size: 20px;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #415ced;
}

.newLanguages {
  position: relative;
  display: inline-block;
}

.newLanguages select {
  width: 100px;
  font-size: 20px;
  height: 52px;
  text-align: center;
  border-radius: 4px;
}
.tickerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 85vw;
  margin: 15px auto;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 8px 0;
  gap: 2px;
}
.weather,
.currency {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  color: rgb(1, 28, 53);
  padding: 4px 12px;
  font-size: 15px;
  letter-spacing: 0.3px;
  position: relative;
}
.weather {
  border-right: 1px solid rgba(68, 68, 68, 0.2);
}
.currency {
  border-left: 1px solid rgba(68, 68, 68, 0.2);
  margin: 2px 0px;
}
.weather::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  width: 1px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(65, 92, 237, 0.3),
    transparent
  );
}
.shadow {
  z-index: 10;
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 0.95) 0%,
    rgba(248, 249, 250, 0.5) 3%,
    rgba(255, 255, 255, 0) 10%
  );
  margin: 0px 10px;
  border-radius: 4px;
  overflow: hidden;
}

@media screen and (max-width: 880px) {
  .tickerContainer {
    max-width: 95vw;
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .weather,
  .currency {
    border: none;
    justify-content: center;
  }
  .weather::after {
    display: none;
  }
  .shadow {
    background: linear-gradient(
      90deg,
      rgba(248, 249, 250, 0.95) 0%,
      rgba(248, 249, 250, 0.5) 5%,
      rgba(255, 255, 255, 0) 15%
    );
  }
  .left article {
    max-width: 250px;
  }
}
@media screen and (max-width: 768px) {
  .top {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .left {
    width: 100%;
    justify-content: center;
  }
  .right {
    justify-content: center;
  }
}
