.science {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.science h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}


.science .info {
    display: flex;
    justify-content: space-between;
    row-gap: 50px;
}

.science .info .left {
    width: calc(100% - 450px);
}
.science .info .right {
    width: 400px;
}
.science .info .menuWrapper {
    position: sticky;
    top: 20px;
}

@media screen and (max-width: 1200px) {
    .science .info {
        flex-direction: column-reverse;
    }
    .science .info .left {
        width: 100%;
    }
    .science .info .right {
        width: 100%;
    }
}