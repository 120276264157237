.main {
    width: 90%;
    max-width: 1640px;
    margin: 0 auto;
    background-color: white;
}

.newsB {
    width: 70%;
    margin: 0 auto;
    background-color: white;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
}

.newsB h1 {
    padding-top: 30px;
    width: 95%;
    margin: 0 auto;
}

.main h1 {
    padding-top: 30px;
    width: 95%;
    margin: 0 auto;
}

.news {
    padding-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 4%;
    row-gap: 30px;
    width: 95%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.filter {
    width: 95%;
    min-height: 60px;
    align-items: center;
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 12px;
    justify-content: space-between;
}

.filter .categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
}

.filter .categories span {
    cursor: pointer;
    display: inline-block;
    border-radius: 15px;
    color: white;
    text-decoration-line: none;
    padding: 4px 9px;
}

.filter .categories .edu {
    background-color: #BA8F95;
}

.filter .categories .science {
    background-color: #D7D9B1;
}

.filter .categories .teen {
    background-color: #CFBCDF;
}

.filter .categories .project {
    background-color: #89669d;
}

.filter .search input {
    padding: 2px 4px;
    max-width: 100%;
}


.newsPage {

    width: 90%;
    color: black;
    margin: 0 auto;
    line-height: 1.5;
    padding-bottom: 20px;
    max-width: 900px;
}

.newsPage h1 {
    width: 100%;
}

.newsPage a {
    text-decoration-line: none;
    cursor: pointer;
    color: #16318a;
}

.newsPage a:hover {
    text-decoration-line: underline;
}

.newsPage .newsImg {
    padding-top: 40px;
    max-width: 100%;
    margin-bottom: 20px;
}
.newsPage .anotherImg {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.newsPage .anotherBigImg {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}


.newsPage .newsImg img{
    /*border: #bbbbb9 1px solid;*/
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
}
.newsPage .anotherImg img{
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
    transition: 0.3s ease-in-out;
}

.newsPage .anotherBigImg img{
    scale: 2;
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
    transition: 0.3s ease-in-out;
}

@media screen and (max-width: 768px){
    .filter .categories {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .filter {
        align-items: flex-start;
    }

}