.main {
    width: calc(90% * 0.95);
    margin: 0 auto;
    max-width: 1640px;
    padding-bottom: 10px;
    min-height: calc(100vh - 385px);
}
.edit input {
    margin-top: 5px;
    font-size: 20px;
    width: 90%;
    max-width: 600px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}
.edit select {
    margin-top: 5px;
    font-size: 20px;
    width: calc(90% + 30px);
    max-width: 630px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}
.edit  {
    color: #4D4D4D;
    font-size: 20px;
}
.error {
    color: red;
    font-size: 18px;
    padding-bottom: 10px;
}
.edit button {
    font-family: 'Jost', sans-serif;

    border: none;
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    color: white;
    background-color: rgba(56, 95, 235, 1);
    border: 1px solid rgba(56, 95, 235, 1);
    padding: 5px 15px;
    margin-top: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    touch-action: manipulation;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.edit button:hover {
    color: rgba(56, 95, 235, 1);
    background-color: white;
}


.loginForm {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin: 0 auto;
    max-width: 400px;
    margin-top: 40px;
}

.loginForm h1 {
    color: black;
    width: 100%;
    font-size: 28px;
    text-align: center;
}

.loginForm .loginInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.loginForm .loginInfo ul {
    font-size: 18px;
}

.loginForm .loginInfo ul li {
    padding-bottom: 8px;
}

.loginForm .loginInfo .wrong {
    color: red;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.registration {
    font-size: 18px;
    text-align: center;

}
.registration a {
    font-weight: bold;
    color: #000000;

}

.loginForm .loginInfo input[type=username] {
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=email] {
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=text] {
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}
.loginForm .loginInfo input[type=password] {
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo button {
    font-family: 'Jost', sans-serif;

    border: none;
    font-size: 20px;
    min-height: 40px;
    border-radius: 15px;
    color: white;
    background-color: rgba(56, 95, 235, 1);
    border: 1px solid rgba(56, 95, 235, 1);
    padding: 5px 15px;
    margin-top: 40px;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    touch-action: manipulation;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.loginForm .loginInfo button:hover {
    color: rgba(56, 95, 235, 1);
    background-color: white;
}


.loginForm .loginInfo label {
    font-size: 20px;
    margin-bottom: 10px;
}


.avatarContainer {
    width: 100%;
    max-width: 666px;
}
.avatar {

    width: 400px;
    height: 400px;

    border-radius: 190px;
    cursor: pointer;
    border: 1px solid black;
}

.avatar img {
    border-radius: 100%;
    width: 400px;
    height: 400px;
}
.edit input[type=file] {
    margin-top: 5px;
    font-size: 20px;
    width: 90%;
    max-width: 630px;
    min-height: 40px;
    border-radius: 15px;

    border: 1px solid #c9c9c9;
    padding-left: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
}
@media screen and (max-width: 536px){

    .avatar {

        width: 300px;
        height: 300px;

        border-radius: 190px;
        cursor: pointer;
        border: 1px solid black;
    }

}
@media screen and (max-width: 368px){

    .avatar {

        width: 200px;
        height: 200px;

        border-radius: 190px;
        cursor: pointer;
        border: 1px solid black;
    }

}