.modal {
    margin: 0 auto;
    padding-top: 20px;
}
.modal button {
    font-family: 'Jost', sans-serif;
    width: 45%;
}
.nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.form {
    font-size: 20px;
    padding-top: 30px;

}
.form input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: white !important;
    color: fieldtext !important;
}
.form input:-webkit-autofill{
    box-shadow:inset 0 0 0 1000px #fff;
}

.error {
    width: 45%;
    color: red;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.info {
    color: black;
    font-size: 16px;
}
.info h2 {
    color: #4D4D4D;
    font-size: 32px;
}