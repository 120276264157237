.banner {
    background-color: #415CED;
    border: 1px solid black;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 40px;
    position: relative;
    padding: 40px 20px;
}

.banner h1 {
    font-size: 3vw;
    font-weight: 700;
    width: 57%;
    padding-left: 80px;
    color: white;
    margin: 0;
}

.banner img {
    position: absolute;
    transform: rotate(0deg);
    bottom: 0px; /* Attach to the bottom */
    right: 0px;
    max-width: 20%; /* Ensure image is responsive */
    height: auto;
    z-index: 2;
}

.column {
    position: absolute;
    height: 310px;
    width: 92px;
    transform: rotate(-140deg);
}

@media screen and (max-width: 1200px){
    .column {
        position: absolute;
        height: 310px;
        width: 50px;
        transform: rotate(-140deg);
    }
}
@media screen and (max-width: 768px){
    .banner {
        border: none;
        padding: 0;
        padding-bottom: 30px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        width: 100%;
        margin-top: 20px;
        border-radius: 0;
    }
    .banner h1 {
        padding-left: 5%;
        margin: 0;
        font-size: 32px;
        width: 90%;
    }
    .banner .mobile {
        position: relative;
        height: 300px;
    }
    .banner .mobile img {
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 400px;
        max-width: 80%;
        height: auto;
    }

    .column {
        position: absolute;
        height: 310px;
        width: 92px;
        transform: rotate(-152deg);
    }
}
@media screen and (max-width: 330px){
    .banner h1 {
        font-size: 24px;
    }
}