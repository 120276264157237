.root {
    width: 90%;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.5;
    max-width: 1640px;
    padding-bottom: 30px;
}

.date {
    padding-top: 30px;
    margin: 0 auto;
    width: 90%;
}

.root h1 {
    padding-top: 30px;
    margin: 0 auto;
    padding-bottom: 20px;
    width: 90%;
}

.layout {
    display: flex;
    column-gap: 30px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.articleDetailBlock {
    padding-bottom: 30px;
    overflow: hidden;
    background: rgba(186, 143, 150, 0.1);
    color: #4D4D4D;
}

.articleDetailBlock article {
    border-bottom: 1px solid rgba(186, 143, 150, 1);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 1;
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1) translateZ(0);
    -ms-transform: scale(1) translateZ(0);
    -o-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
}

.articleDetailBlock img {
    width: 90%;
    margin-left: 5%;
}

.articleDetailBlock article .title {
    padding-top: 30px;
    width: 90%;
    margin: 0 auto;
}

.text {
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
}

.main {

    width: 100%;
}

.bottom {

}

.bottom .also{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.bottom .also  h2 {
    padding-top: 30px;
    margin: 0;
    color: #4D4D4D;
    padding-bottom: 30px;
    font-weight: 400;
}
.bottom .also a {
    background-color: rgba(36, 36, 36, 1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    color: white;
    text-decoration-line: none;
    font-size: 24px;
    border-radius: 4px;
    padding: 16px 24px 16px 24px;
    -webkit-transition: background 0.3s ease;
    -moz-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    -ms-transition: background 0.3s ease;
    transition: background 0.3s ease;
}

.bottom .also a:active {
    background-color: white;
    border: 1px solid rgba(36, 36, 36, 1);
    color: rgba(36, 36, 36, 1);
}

.bottom .also a .arrow {
    color: white;
    padding-left: 5px;
}
.bottom .also a:active .arrow {
    color: rgba(36, 36, 36, 1);
}
