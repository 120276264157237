.flags {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 75px;
}
.flags .info {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;

}
.flags .info h2 {
    font-size: 60px;
    padding-top: 30px;
    margin: 0;
    color: #4D4D4D;
    padding-bottom: 30px;
    font-weight: 700;
}

.flagFlex {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5%;
    row-gap: 30px;
}
.flagUrl {
    width: 16%;
    row-gap: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #4D4D4D;
}
.flagUrl img {
    width: 100%;
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    .flagFlex {
        justify-content: space-around;
    }

    .flagFlex {
        column-gap: 10%;

    }
    .flagUrl {
        width: 45%;
    }
}