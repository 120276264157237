.history {
    position: relative;
    margin-top: 30px;
    border-radius: 20px;
    border: 1px solid black;
    color: #4D4D4D;
    padding: 40px 70px 70px;
    font-size: 24px;
    margin-bottom: 210px;
}

.history .partners {
    padding: 30px;
    color: white;
    position: absolute;
    display: flex;
    background: #415CED;
    border-radius: 20px;
    border: 1px solid black;
    column-gap: 20px;
    max-width: 850px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -190px;
    align-items: center;
}
.history .partners .count{
    text-align: center;
    display: flex;
    flex-direction: column;
}
.history .partners .description {
    max-width: 480px;
    margin: 0 auto;
}
.history .partners .count .num{
   margin-top: -40px;
    font-size: 148px;
    font-weight: 700;
    margin-bottom: -40px;
}
.history .partners .count .word{
    font-size: 24px;
    font-weight: 900;
}
@media screen and (max-width: 768px) {
    .history {
        padding: 0;
        padding-top: 40px;
        padding-bottom: 70px;
        border-radius: 0;
        border: 1px solid black;
        border-right: 0;
        border-left: 0;
        font-size: 18px;
        margin-bottom: 310px;
    }
    .history .info {
        width: 90%;
        margin: 0 auto;
    }
    .history .partners {
        padding-left: 25px;
        padding-top: 35px;
        padding-right: 25px;
        width: calc(90% - 60px);
        max-width: 350px;
        flex-direction: column-reverse;
        row-gap: 20px;
        bottom: -290px;
    }
}
@media screen and (max-width: 460px) {
    .history {
        margin-bottom: 330px;
    }
    .history .partners {
        bottom: -310px;
    }
}
@media screen and (max-width: 355px) {
    .history {
        margin-bottom: 360px;
    }
    .history .partners {
        bottom: -340px;
    }
}