.infoBlocks {
  display: flex;
  gap: 32px;
  margin: 40px auto;
  width: 90%;
  max-width: 1640px;
}

.infoBlock {
  flex: 1;
  padding: 32px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.infoBlock:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.infoBlock h3 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
}

.infoBlock p {
  color: #666;
  margin-bottom: 24px;
}

.infoBlock button {
  background-color: #415ced !important; /* Синий цвет как на сайте */
  color: white !important;
  border: none !important;
  height: 40px;
  padding: 0 24px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.infoBlock button:hover {
  background-color: #3348c5 !important; /* Немного темнее при наведении */
  box-shadow: 0 2px 8px rgba(65, 92, 237, 0.3);
}

@media (max-width: 768px) {
  .infoBlocks {
    flex-direction: column;
    gap: 16px;
    margin: 20px auto;
  }

  .infoBlock {
    padding: 24px;
  }

  .infoBlock h3 {
    font-size: 20px;
  }
}
