.forum {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.forum h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}

.sidebar {
    width: 25%;
    padding: 20px;
}

.content {
    width: 75%;
    padding: 20px;
}

.placeholder {
    font-size: 20px;
    color: #888;
}

.threadList {
    list-style: none;
    padding: 0;
}

.threadList h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.threadList ul {
    padding: 0;
}

.threadList li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.threadList li:hover {
    background-color: #f0f0f0;
}

.thread {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.chatRoom {
    display: flex;
    flex-direction: column;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.message {
    margin-bottom: 10px;
}

.inputContainer {
    display: flex;
    border-top: 1px solid #ccc;
    padding: 10px;
}

.input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.sendButton {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.sendButton:hover {
    background-color: #0056b3;
}

.nav {
    display: flex;
    justify-content: space-between;
}

.nav .left {
    width: 75%;
}

.answer {
    font-size: 16px;
    color: #7e95b1;
}

@media screen and (max-width: 1200px) {
    .nav .left {
        width: 65%;
    }
}