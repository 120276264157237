.forum {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;
}

.forum h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}

.author {
    border: 1px solid #4D4D4D;
    border-radius: 16px;
    font-size: 18px;
}

.author img {
    max-width: 100%;
    border-radius: 16px;
}

.author .info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: space-between;
    padding: 10px 20px 20px;
}
.author .info .name{
    text-align: center;
}